import React from "react";
import Layout from "../components/layout";
import Hero from "../components/index/hero";
import { useStaticQuery, graphql, Link } from "gatsby";
import SEO from "../components/SEO";

export default function Recruiting() {
  const { heroImage } = useStaticQuery(graphql`
    query appQuery {
      heroImage: file(relativePath: { eq: "index.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO />
      <Hero
        image={heroImage}
        title="IBB Recruiting"
        tagline="Want to join a team of leaders and biology enthusiants? Take a look our open positions below and we'll see if you're a fit!"
        button={{link: "#", label: 'Apply'}}
      />
      <div className="max-w-screen-xl mx-auto py-10 px-4 sm:px-6">
        <div className="flex flex-wrap overflow-hidden">
          <div
            style={{ marginBottom: "24px" }}
            className="my-3 px-3 w-full "
          >
            <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-8">
              Asian Biology Bowl
            </h1>
            <p className="mb-4 text-gray-500">
              We are looking for team members to hold Director, Outreach, and Content positions for our Asian Biology Bowl (ABB)
	  <br />
              Applications should meet the following criteria:
            </p>
            <ul className="space-y-1 text-gray-500 list-disc list-inside ">
                <li>Reside Outside of the United States</li>
                <li>Equivalent of a Sophomore or Higher (Class 10)</li>
                <li>Knowledgeable in the Biological Sciences</li>
                <li>Willing to put in time Time and Commitment</li>
            </ul>
            <br />
            <a
              href="https://bit.ly/APPLYABB2024"
              className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out"
            >
              Apply Now!
            </a>
            <br />
          </div>

          <div className="flex flex-wrap overflow-hidden">
          <div
            style={{ marginBottom: "24px" }}
            className="my-3 px-3 w-full "
          >
            <h1 className="text-2xl tracking-tight leading-10 sm:leading-none font-extrabold text-gray-900 sm:text-3xl lg:text-4xl xl:text-5xl mb-4 sm:mb-8">
              USA Biology Bowl
            </h1>
            <p className="mb-4 text-gray-500">
              We are looking for team members to hold Outreach, Content, and Technology positions for our USA Biology Bowl (USABB)
	  <br />
              Applications should meet the following criteria:
            </p>
            <ul className="space-y-1 text-gray-500 list-disc list-inside ">
                <li>Must be a high schooler</li>
                <li>Must be proficient in relevant skills</li>
                <li>Willing to put in time Time and Commitment</li>
            </ul>
            <br />
            <a
              href="https://shorturl.at/drFQ2"
              className="bg-blue-500 hover:bg-blue-400 focus:outline-none focus:bg-blue-400 px-5 py-3 border border-transparent text-base font-medium rounded-md text-white transition duration-150 ease-in-out"
            >
              Apply Now!
            </a>
            <br />
          </div>
          </div>

        </div>
      </div>
    </Layout>
  );
}
